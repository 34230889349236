import { parseValidatorsList }                  from '@cs/components/shared/validation/validatation.helpers';
import {
	DataAnnotation,
	DataDescribed,
	DataGroup,
	FormatRegisteredItem,
	generateIdObject,
	getIdHelperObj,
	getPropertyOf,
	LoggerUtil,
	Lookup,
	pathChecked,
	PropertyAnnotation,
	TableDataCell,
	TableDataDescribed,
	TableDataGroupLayout,
	TableDataRowLayout,
	TableDataRowType,
	TableHeader,
	TableHeaderAnnotation,
	TableHeaderRowAnnotation,
	TableHeaderRowLayout,
	TableLayout,
	LayoutAnnotationTable, gv, gvb, hasPropertyOf, generateQuickGuid, isUndefined
}                                               from '@cs/core';
import { isBoolean, isDate, isNullOrUndefined } from '@cs/core';
import { Logger }                               from '@cs/components/util';
import { isNumberValue }                        from '@cs/components/util';
import { parseTypes }                           from '@cs/common';


export class CsTableNxtParser {
	static addEmptyRow<T>(renderSchema: TableLayout<T>, dataDescr: TableDataDescribed<T[]>) {
		const keyHelperObj  = getIdHelperObj(dataDescr.dataAnnotation.fields);
		const lastHeaderRow = renderSchema.getLastHeaderRow();
		const layout        = renderSchema.layout;

		if (renderSchema.dataGroups.length === 0) {
			const group    = new TableDataGroupLayout({});
			group.children = [];
			renderSchema.dataGroups.push(group);

		}

		// Loop over the groups
		for (const dataGroup of renderSchema.dataGroups) {


			const dataRow = [];
			const id      = generateQuickGuid();

			// Create Table Rows
			const row   = this.getRows(false, true, false,
																														new TableDataRowLayout(generateIdObject(dataRow, keyHelperObj), TableDataRowType.Data),
																														lastHeaderRow.headers, dataRow, new DataGroup<any>({
																																																																																		values:  [],
																																																																																		groupBy: ''
																																																																																	}), dataDescr);
			row.isEmpty = true;
			renderSchema.rowIndexMapper.set(row.rowId, dataGroup.rows.length);
			dataGroup.rows.push(row);


		}
	}

	static parseDataAnnotations<T>(dataDescr: TableDataDescribed<T>): TableLayout<T> {
		const layout = this.parseLayoutAnnotation(dataDescr);
		// this.calculateOrderAndColspanGroupedHeader<T>(layout, dataDescr);
		return layout;
	}

	private static parseLayoutAnnotation<T>(dataDescr: TableDataDescribed<T>) {
		const layout      = new LayoutAnnotationTable(dataDescr.layout);
		const tableLayout = new TableLayout<T>(layout.table);

		const headerRow              = new TableHeaderRowLayout('headers'); // tableLayout.headerRows[tableLayout.headerRows.length - 1];
		const headers: TableHeader[] = [];

		layout.table.headerRows = CsTableNxtParser.detectHeaderRows(dataDescr);
		layout.table.headers    = CsTableNxtParser.getColumns(dataDescr);


		if (layout.table.headerRows.length === 0) {
			dataDescr.dataAnnotation.fields.forEach((field) => {
				if (field.visible === false)
					return;

				const th = this.createHeader(field, dataDescr, layout);
				if (!isNullOrUndefined(th))
					headers.push(th);
			});
			headerRow.headers = headers;
			tableLayout.headerRows.push(headerRow);
		} else if (layout.table.headerRows.length > 0) {
			const groupedMap = layout.table.headers.reduce(
				(entryMap, e) => entryMap.set(e.idHeaderRow, [...entryMap.get(e.idHeaderRow) || [], e]),
				new Map()
			);

			// find all headers that have no group and map them to a TableHeaderAnnotation
			const allNotDefinedInLayout = dataDescr.dataAnnotation.fields
																																										.filter(f =>
																																																			layout.table.headers.find(h => h.id === f.id) === undefined)
																																										.map((value: PropertyAnnotation<T>) => new TableHeaderAnnotation<any>({
																																																																																																																	id: value.id
																																																																																																																})
																																										);

			if (groupedMap.has(undefined)) {
				groupedMap.set(undefined, [...groupedMap.get(undefined), ...allNotDefinedInLayout]);
			}

			groupedMap.forEach((value: TableHeaderAnnotation<T>[], key) => {
				// loop over the grouped headers and foreach grouped header create the nested headers
				// |----|----| <-- Grouped headers line
				// |-|--|--|-| <-- this TH line is filled by the function below
				headers.push(...value.map((v, index) => {
																										const annot  = dataDescr.dataAnnotation.fields.find(da => da.id === v.id);
																										const header = this.createHeader(annot, dataDescr, layout);
																										// If the header is the first of the nested group give it a class
																										if (index === 0 && key !== undefined && header !== undefined) {
																											header.classList.push('table-nxt-grouped-header-first-header');
																										}
																										return header;
																									})
																									.filter(v => !isNullOrUndefined(v)));
			});

			if (layout.table.headers.length === 0) {
				headerRow.headers = headers;
			} else {
				// headers.forEach(h => {
				//   const header = layout.table.headers.find(th => th.id === h.id);
				//   if (isNullOrUndefined(header)) return;
				//
				//   for (const key of Object.keys(header)) {
				//     if (!isNullOrUndefined(header[key])) {
				//       h[key] = header[key];
				//     }
				//   }
				// });

				headerRow.headers = headers;
			}

			tableLayout.headerRows.push(headerRow);
			this.addGroupedHeader<T>(groupedMap, layout.table.headerRows, layout, tableLayout);
		}

		const typedData = !Array.isArray(dataDescr.data)
																				? [dataDescr.data]
																				: dataDescr.data;
		// Get dataGroup, if the dataGroup doesn't exist it will be created so the table has one group
		const groupData = (isNullOrUndefined(dataDescr.dataGroups) || dataDescr.dataGroups.length === 0)
																				?
																				[{'groupBy': null}]
																				: dataDescr.dataGroups;

		if (isNullOrUndefined(dataDescr.data) || typedData.length === 0)
			LoggerUtil.warn(`Data is empty`);
		else {

			const keyHelperObj  = getIdHelperObj(dataDescr.dataAnnotation.fields);
			const lastHeaderRow = tableLayout.headerRows[tableLayout.headerRows.length - 1];
			let group: TableDataGroupLayout;

			// Loop over the groups
			for (const dataGroup of groupData) {
				// Check if the group is the Total Row
				const isTotal = dataGroup.groupBy === null
																				? !isNullOrUndefined(dataGroup.values)
																				: false;

				// Order data if it's grouped
				if (dataGroup.groupBy !== null) {

					if (layout.table.preserveDataOrderWhenGrouping) {
						const sortOrder  = dataGroup.values;
						const fromValues = sortOrder.map(f => f[dataGroup.groupBy]);
						typedData.sort((a, b) => {
							if (fromValues.indexOf(a[dataGroup.groupBy]) < fromValues.indexOf(b[dataGroup.groupBy]))
								return -1;
							if (fromValues.indexOf(a[dataGroup.groupBy]) > fromValues.indexOf(b[dataGroup.groupBy]))
								return 1;
							return 0;
						});
					} else {
						// original sorting behaviour (unpredictable)
						const sortOrder = dataGroup.values;
						typedData.sort((a, b) => {
							const fromValues = sortOrder.map(f => f[dataGroup.groupBy]);
							return fromValues.indexOf(a[dataGroup.groupBy]) < fromValues.indexOf(b[dataGroup.groupBy])
														? -1
														: 1;
						});
					}
				}

				for (let rowDataIndex = 0; rowDataIndex < typedData.length; rowDataIndex++) {
					const dataRow  = typedData[rowDataIndex];
					const id       = !isNullOrUndefined(dataRow[dataGroup.groupBy])
																						? dataRow[dataGroup.groupBy]
																						: 'Total';
					group          = new TableDataGroupLayout(id);
					group.children = [];
					// Check if the group exist find it on the tableLayout or create the group
					// This is done because the data cotains the groups
					if (!isNullOrUndefined(tableLayout.dataGroups.find(x => x.id === group.id))) {
						group = tableLayout.dataGroups.find(x => x.id === group.id);
					} else {
						// If the groupBy is null is a total row or is not grouped
						if (dataGroup.groupBy !== null) {

							if (dataGroup.groupBy === 'group')
								throw new Error(`The group by propery could not be named: 'group'. It's used internally by the component.`);

							// Create Grouping Rows
							const groupRow = this.getRows(true, false, false,
																																					new TableDataRowLayout(
																																						{'group': dataGroup.groupBy, [dataGroup.groupBy]: dataRow[dataGroup.groupBy]},
																																						TableDataRowType.Group),
																																					this.getGroupValues(dataGroup.values, dataDescr, dataGroup.groupBy,
																																																									dataRow[dataGroup.groupBy]), dataRow, dataGroup, dataDescr);
							group.rows.push(groupRow);
						}
					}

					// Creating data rows unless its a total row
					if (dataRow[dataGroup.groupBy] || (isTotal && groupData.length === 1) || !isTotal) {
						if (isNullOrUndefined(lastHeaderRow.headers)) {
							LoggerUtil.warn(`Last HeaderRow is null`);
						} else {
							// Create Table Rows
							const row = this.getRows(false, true, false,
																																new TableDataRowLayout(generateIdObject(dataRow, keyHelperObj), TableDataRowType.Data),
																																lastHeaderRow.headers, dataRow, dataGroup, dataDescr);
							tableLayout.rowIndexMapper.set(row.rowId, rowDataIndex);
							group.rows.push(row);
						}
					}
					// Add group if it doesn't exist on the tableLayout
					if (isNullOrUndefined(tableLayout.dataGroups.find(x => x.id === group.id))) {
						tableLayout.dataGroups.push(group);
					}
				}
				if (isTotal) {
					// Create Total Row
					const groupRow = this.getRows(false, false, true,
																																			new TableDataRowLayout({'group': 'TotalRow'}, TableDataRowType.Total),
																																			this.getGroupValues(dataGroup.values, dataDescr), null, groupData[0], dataDescr);
					group.rows.push(groupRow);
				}
			}

		}

		// Workaround for UNL-588 Random Column header in R&D tree
		this.patchFirstColumnLabelWhenGrouped(dataDescr, tableLayout);

		return tableLayout;
	}

	/**
		* Get the configuration for each row according to the type
		* @param isGroupRow get if row is from a group
		* @param isTableRow get if row is not from a group
		* @param isTotalRow get if row is from a total
		* @param row information of the row
		* @param headers table headers
		* @param dataRow row of data
		* @param dataGroup groups
		* @param dataDescribe the data describe model
		*/
	private static getRows(isGroupRow: boolean, isTableRow: boolean, isTotalRow: boolean, row: TableDataRowLayout,
																								headers: TableHeader[], dataRow: any, dataGroup: DataGroup<any>, dataDescribe: TableDataDescribed<any>): TableDataRowLayout {

		let value;
		for (let index = 0; index < headers.length; index++) {
			const header             = headers[index];
			const propertyAnnotation = dataDescribe.getProperty(header.id);
			const fieldName          = header.id;
			value                    = isTableRow
																														? isUndefined(dataRow[fieldName])
																																? header.defaultValue
																																: dataRow[fieldName]
																														: isNullOrUndefined(value)
																																? header.value
																																: value;
			if ((fieldName === dataGroup.groupBy && isGroupRow) || (fieldName === dataGroup.groupBy && isTotalRow)) {
				continue;
			}
			// create new data cell
			const tdc = new TableDataCell(fieldName, value);
			if (!isNullOrUndefined(header.classList.find(s => s === 'table-nxt-grouped-header-first-header'))) {
				tdc.classList.push('table-nxt-grouped-header-first-cell');
			}
			if (header.classList) {
				tdc.classList.push(...header.classList);
			}
			if ((dataGroup.groupBy !== null && dataGroup.groupBy !== '')
				&& isTableRow
				&& index === 0
			) {
				tdc.classList.push('group-0');
			}

			let foundLookupValue = null;
			// Check if the annotation has a lookup if so resolve the value
			if (!isNullOrUndefined(header.lookup)) {
				foundLookupValue = this.resolveValue(value, header.lookup, `for fieldname '${fieldName}'`);

				if (foundLookupValue)
					tdc.displayValue = foundLookupValue;
			}

			// check for fallback template
			if (foundLookupValue == null && header.lookupName && header.noLookupFallbackTemplate) {
				tdc.template = CsTableNxtParser.getCellTemplate(tdc, header, true);
			} else
				tdc.template = CsTableNxtParser.getCellTemplate(tdc, header);


			tdc.validators     = CsTableNxtParser.parseValidators(tdc, propertyAnnotation);
			tdc.state.readOnly = header.readOnly;
			tdc.parentRowId    = row.rowId;
			row.cells.push(tdc);
			value = null;
		}

		// Styling for the group row
		if (isGroupRow)
			row.classList.push('group-row');
		else if (isTotalRow)
			row.classList.push(...['group-row', 'is-total-row']);

		// Collasable setting
		if (!isNullOrUndefined(dataGroup.isCollapsible)) {
			if (isGroupRow) {
				row.cells[0].collapseIcon = dataGroup.isCollapsible;
			} else if (isTableRow) {
				row.isCollapsible = dataGroup.isCollapsible;
			}
		}

		return row;
	}

	/**
		* Get the group values with the group parameter
		* @param valuesGroup values from the group
		* @param dataDescr data described from the table
		* @param valueLabel Label of the value
		* @param group Label of the group
		*/
	private static getGroupValues(valuesGroup, dataDescr: TableDataDescribed<any>, valueLabel: string = null, group = null) {
		const headers = [];
		for (const values of valuesGroup) {
			if ((valueLabel === null && group === null) || (values[valueLabel] === group)) {
				for (const key of Object.keys(values)
																												.filter(keyName => dataDescr.dataAnnotation.fields
																																																								.find(field => field.visible !== false && keyName === field.id))) {
					const dataType = dataDescr.dataAnnotation.fields.find(field => field.id === key).type;
					const value    = values[key];
					headers.push(new TableHeader(key, value, {
						dataType: parseTypes(dataType)
					}));
				}
				return headers;
			} else {
				LoggerUtil.debug(`${values[valueLabel]} is not ${group}`);
			}
		}
	}

	private static getCellTemplateByValue(tdc: TableDataCell, header: TableHeader) {
		let out;

		if (!isNullOrUndefined(header.cellTemplate)) {
			tdc.classList.push('text-center-align');
			header.classList.push('text-center-align');
			return header.cellTemplate;
		}

		if (isNumberValue(tdc.displayValue) !== false) {
			tdc.classList.push('text-right-align');
			header.classList.push('text-right-align');
		} else if (Array.isArray(tdc.displayValue)) {
			tdc.classList.push('text-center-align');
			header.classList.push('text-center-align');
		} else if (isBoolean(tdc.displayValue)) {
			tdc.classList.push('text-center-align');
			header.classList.push('text-center-align');
		} else if (isDate(tdc.displayValue)) {
			tdc.classList.push('text-right-align');
			header.classList.push('text-right-align');
		} else {
			header.classList.push('text-left-align');
		}

		if (header.readOnly) {
			if (Array.isArray(tdc.displayValue)) {
				out = 'readOnlyArrayTpl';
			} else if (header.dataType === 'boolean') {
				tdc.classList.push('align-items-center');
				out = 'readOnlyBooleanTpl';
			} else {
				return null;
			}
			return out;
		}

		if (isNumberValue(tdc.displayValue)) {
			out = 'defaultNumberTpl';
		} else if (Array.isArray(tdc.displayValue)) {
			out = 'defaultArrayTpl';
		} else if (isBoolean(tdc.displayValue)) {
			out = 'defaultBooleanTpl';

		} else if (isDate(tdc.displayValue)) {
			out = 'defaultDateTpl';
		} else {
			out = 'defaultStringTpl';
		}
		return out;
	}

	private static getCellTemplate(tdc: TableDataCell, header: TableHeader, fallBack = false) {
		let out;

		if (!isNullOrUndefined(header.cellTemplate) && !fallBack) {
			tdc.classList.push('text-center-align');
			header.classList.push('text-center-align');
			return header.cellTemplate;
		}

		if (fallBack && !isNullOrUndefined(header.noLookupFallbackTemplate)) {
			tdc.classList.push('text-center-align');
			header.classList.push('text-center-align');
			// if not the deafult string template use the provided template, otherwise let the function handle the celltype
			if (header.noLookupFallbackTemplate !== 'defaultStringTpl') {
				return header.noLookupFallbackTemplate;
			}
		}

		if (isNullOrUndefined(header.dataType)) {
			return CsTableNxtParser.getCellTemplateByValue(tdc, header);
		}

		if (header.dataType === 'number') {
			tdc.classList.push('text-right-align');
			header.classList.push('text-right-align');
		} else if (header.dataType === 'boolean') {
			tdc.classList.push('text-center-align');
			header.classList.push('text-center-align');
		} else if (header.dataType === 'date') {
			tdc.classList.push('text-right-align');
			header.classList.push('text-right-align');
		} else if (Array.isArray(tdc.displayValue)) {
			tdc.classList.push('text-center-align');
			header.classList.push('text-center-align');
		} else {
			header.classList.push('text-left-align');
		}

		if (header.readOnly) {
			if (Array.isArray(tdc.displayValue)) {
				out = 'defaultArrayTpl';
			} else if (header.dataType === 'boolean') {
				tdc.classList.push('align-items-center');
				out = 'readOnlyBooleanTpl';
			} else {
				return null;
			}
			return out;
		}

		if (header.dataType === 'number') {
			out = 'defaultNumberTpl';
		} else if (Array.isArray(tdc.displayValue)) {
			out = 'defaultArrayTpl';
		} else if (header.dataType === 'boolean') {
			out = 'defaultBooleanTpl';
		} else if (header.dataType === 'date') {
			out = 'defaultDateTpl';
		} else {
			out = 'defaultStringTpl';
		}
		return out;
	}

	private static generateIdByKeys<T>(dataRow: (Array<T> | T) | T, dataAnnotation: DataAnnotation<T>) {

	}

	/**
		* Try to find the lookup in the Describe Data
		* @param lookupName Name of the lookup
		* @param lookups list of lookups
		* @returns result of the search, should be a lookup
		*/
	private static getLookup(lookupName: string, lookups: Lookup[]): Lookup {
		if (isNullOrUndefined(lookups)) {
			Logger.Warning(`${lookupName} is not found in the lookups, because there are not lookups provided`);
			return null;
		}
		const foundLookup = lookups.find(l => l.id === lookupName);
		if (isNullOrUndefined(foundLookup)) {
			Logger.Warning(`${lookupName} is not found in the lookups`);
			return null;
		}
		return new Lookup(foundLookup);
	}

	/**
		* Resolve the value to a lookup value
		* @param  fieldValue the value that should be resolved
		* @param  lookup the lookup where value is searched
		*/
	private static resolveValue(fieldValues: any[], lookup: Lookup, context: string = 'has no context') {
		if (isNullOrUndefined(fieldValues)) {
			// LoggerUtil.warn(`Value for ${context} is not present in the data `);
			return null;
		} else if (isNullOrUndefined(lookup)) {
			LoggerUtil.error(`Lookup is not found and ${context}`);
			return null;
		} else if (isNullOrUndefined(lookup.values)) {
			LoggerUtil.warn(`Lookup contains no values`);
			return null;
		}

		if (fieldValues.length === 0) {
			LoggerUtil.log(`value is empty array for ${lookup.id} and ${context}`);
			return null;
		}

		let foundValues;

		if (Array.isArray(fieldValues)) {
			const found = fieldValues.map(value => lookup.values.find(l => l.key === value))
																												.filter(value => !isNullOrUndefined(value));
			foundValues = found.map(item => item.value);

			if (isNullOrUndefined(foundValues)) {
				LoggerUtil.warn(`${fieldValues.join(',')} is not found is ${lookup.id} and ${context}`);
				return null;
			}
		} else {
			const found = lookup.values.find(l => l.key === fieldValues);

			if (!isNullOrUndefined(found))
				foundValues = found.value;

			if (isNullOrUndefined(foundValues)) {
				LoggerUtil.warn(`${fieldValues} is not found is ${lookup.id} and ${context}`);
				return null;
			}
		}

		return foundValues;
	}

	private static calculateOrderAndColspanGroupedHeader<T>(layout: TableLayout<T>, dataDescr: TableDataDescribed<T>) {
		if (isNullOrUndefined(layout.headerRows) || layout.headerRows.length === 0
			|| isNullOrUndefined(dataDescr.layout.table.headers) || dataDescr.layout.table.headers.length === 0)
			return;

		// Get the headers
		const groupedHeaderRow = new TableHeaderRowLayout('headers');


		const headerRow = layout.headerRows[layout.headerRows.length - 1];


		// Group the data based on the idHeaderRow Key
		// this indicates that there should be a new header row created
		const groupedMap2 = dataDescr.layout.table.headerRows.reduce(
			(entryMap, e) => entryMap.set(e.idHeaderRow, [...entryMap.get(e.idHeaderRow) || [], e]),
			new Map()
		);

		for (let index = 0; index < headerRow.headers.length; index++) {
			const layoutHeader = headerRow.headers[index];
			const header       = dataDescr.layout.table.headers.find(h => h.id === layoutHeader.id);
			if (!isNullOrUndefined(header)) {
				if (header.idHeaderRow) {
					const groupedHeader = dataDescr.layout.table.headerRows.find(hr => hr.id === header.idHeaderRow);
					if (isNullOrUndefined(groupedHeader)) {
						LoggerUtil.error(`No header found for ${header.idHeaderRow}`);
						continue;
					}
					groupedHeaderRow.headers.push(new TableHeader(groupedHeader.id, groupedHeader.label));
				} else {
					groupedHeaderRow.headers.push(new TableHeader(index.toString(), ''));
				}
			} else {
				groupedHeaderRow.headers.push(new TableHeader(index.toString(), ''));
			}
		}

		layout.headerRows.splice(0, 0, groupedHeaderRow);
	}

	private static addGroupedHeader<T>(previousGroupedHeaders: Map<any, any>, headers: TableHeaderRowAnnotation[]
		, layout: LayoutAnnotationTable<T>, tableLayout: TableLayout<T>) {
		if (isNullOrUndefined(tableLayout.headerRows) || tableLayout.headerRows.length === 0
			|| isNullOrUndefined(layout.table.headerRows) || layout.table.headerRows.length === 0)
			return;

		// // Group the data based on the idHeaderRow Key
		// // this indicates that there should be a new header row created
		// const groupedHeaders = headers.reduce(
		//   (entryMap, e) => entryMap.set(e.idHeaderRow, [...entryMap.get(e.idHeaderRow) || [], e]),
		//   new Map()
		// );
		// console.log(groupedHeaders);
		const headerLayoutRow = new TableHeaderRowLayout('1');
		// For the grouped headercolumns filter out the headers that have a 'default' idHeaderRow and are an key in the GROUPED map
		previousGroupedHeaders.forEach((value: TableHeaderRowAnnotation[], key) => {

			if (isNullOrUndefined(key)) {
				const th   = new TableHeader(undefined, '');
				th.colSpan = value.length;
				headerLayoutRow.headers.push(th);
			} else {
				const tha  = layout.table.headerRows.find(o => o.id === key);
				const th   = new TableHeader(tha.id, tha.label);
				th.colSpan = value.length;
				th.classList.push('table-nxt-grouped-header');
				headerLayoutRow.headers.push(th);
			}
		});
		tableLayout.headerRows.splice(0, 0, headerLayoutRow);
	}

	private static createHeader<T>(annot: PropertyAnnotation<T>, dataDescr: DataDescribed<T>, layout: LayoutAnnotationTable<T>) {


		const fieldName = annot.id.toString();
		// Ignore the id column
		if (layout.table.layout.ignoreIdColumn) {
			if ((annot.generatedKey === true || annot.key === true) && !annot.visible)
				return;
		}


		const header = layout.table.headers.find(th => th.id === annot.id);

		// Hide the columns that have the property include false
		if (isNullOrUndefined(header) || (!isNullOrUndefined(header.include) && !header.include)) return;

		const tableHeader = new TableHeader(fieldName, annot.label, {
			readOnly:                 gv(() => layout.table.isReadOnly, true)
																													? true
																													: annot.readOnly,
			cellTemplate:             getPropertyOf(header, 'cellTemplate', null),
			cellFormat:               new FormatRegisteredItem(null, getPropertyOf(header, 'cellFormat', null)),
			classList:                getPropertyOf(header, 'classList', '')
																														.split(' '),
			description:              annot.description,
			conditionalFormatting:    getPropertyOf(header, 'conditionalFormatting', []),
			allowAsFilter:            isNullOrUndefined(annot.canFilter)
																													? getPropertyOf(header, 'allowAsFilter', true)
																													: annot.canFilter,
			dataType:                 !isNullOrUndefined(annot.type)
																													? parseTypes(annot.type)
																													: annot.type,
			isSortable:               annot.canSort,
			width:                    header.width,
			noLookupFallbackTemplate: header.noLookupFallbackTemplate,
			defaultValue:             getPropertyOf(annot, 'defaultValue', null)

		});

		if (annot.selectionTrigger === 'Property' || annot.selectionTrigger === 'Entity') {
			tableHeader.classList.push('cell-ipa-clickable');
			if (annot.selectionTrigger === 'Property')
				tableHeader.classList.push('cell-ipa-clickable-property');
		}

		// Check if the annotation has a lookup if so add it to the header
		if (!isNullOrUndefined(annot.lookup)) {
			tableHeader.lookup     = this.getLookup(annot.lookup, dataDescr.lookups);
			tableHeader.lookupName = annot.lookup;
		}

		return tableHeader;
	}

	private static detectHeaderRows<T>(dataDescr: TableDataDescribed<T>) {
		const groups = pathChecked(dataDescr, ['dataAnnotation', 'groups'], [], false);
		return groups.map(value => new TableHeaderRowAnnotation({id: value.id, label: value.label}));
	}

	private static getColumns<T>(dataDescr: TableDataDescribed<T>) {
		const headers = pathChecked(dataDescr, ['layout', 'table', 'headers'], null, false);
		// check if there are headers defined in the layout. This will override the default behaviour

		return dataDescr.dataAnnotation.fields.map(value => {
																			if (this.includeColumn(dataDescr, value)) {
																				const layoutHeader = {
																					id:           value.id,
																					label:        value.label,
																					readOnly:     value.readOnly,
																					cellFormat:   value.format,
																					cellTemplate: value.cellTemplate,
																					idHeaderRow:  value.groupId
																				};
																				if (headers !== null) {
																					const layoutField = headers.find(header => value.id === header.id);
																					if (!isNullOrUndefined(layoutField))
																						Object.assign(layoutHeader, layoutField);
																				}

																				return new TableHeaderAnnotation(layoutHeader);
																			}
																		})
																		.filter(x => x !== undefined);
	}

	public static includeColumn<T>(dataDescr: TableDataDescribed<T>, value: PropertyAnnotation<T>): boolean {

		if (!isNullOrUndefined(dataDescr.layout.table.headers)
			&& dataDescr.layout.table.headers.find(x => (x.id === value.id && x.include === false))) {
			return false;
		} else if (dataDescr.dataAnnotation.fields.find(field => (field.id === value.id && field.visible === false))) {
			return false;
		}
		return true;
	}

	/**
		* Apply the label for the first column to be the label of the grouped column
		*/
	private static patchFirstColumnLabelWhenGrouped(dataDescr: TableDataDescribed<any>, layout: TableLayout<any>) {
		const headers = dataDescr.dataGroups;
		if (headers != null && headers.length > 0) {
			const {groupBy, isCollapsible} = headers[0];

			if (!isCollapsible)
				return;

			const foundHeader                                 = dataDescr.dataAnnotation.fields.find(value => value.id === groupBy);
			layout.getLastHeaderRow().headers[0].displayValue = foundHeader.label;
		}
	}


	private static parseValidators(tdc: TableDataCell, value: PropertyAnnotation<any>): any {

		return parseValidatorsList(value.validators, null, value.type);

	}
}
